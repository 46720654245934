import { createAction, props } from '@ngrx/store';
import { AnalyticsReducerInitialState } from '../reducers/admin-analytics.reducer';
import { QueryClient } from '@tanstack/angular-query-experimental';

const prefix = '[Admin-analytics]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<AnalyticsReducerInitialState>>()
);

export const setListAction = createAction(`${prefix} set list`, props<any>());

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<{
    minDate: string;
    maxDate: string;
    esId: string;
    pgId?: string;
    pcId: string;
    tpcId?: string;
    mdaId?: string;
    toId?: string;
    successAction?: (queryClient: QueryClient) => any;
  }>()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<{ successAction?: (queryClient: QueryClient) => any }>()
);

export const setSearchAction = createAction(
  `${prefix} set search`,
  props<{
    keyword: string;
  }>()
);

export const setDocAction = createAction(
  `${prefix} set doc`,
  props<{ id: string }>()
);

export const setLgaCardAction = createAction(
  `${prefix} set lga card`,
  props<any>()
);

export const setPercentagePaidUnpaidCardAction = createAction(
  `${prefix} set paid unpaid card`,
  props<any>()
);

export const setTotalGeneratedRevenueCardAction = createAction(
  `${prefix} set total generated revenue card`,
  props<any>()
);

export const setTotalInvoicePaidByGatewayCardAction = createAction(
  `${prefix} set total invoice Paid by payment gateway card`,
  props<any>()
);

export const setPercentageInvoicePaidByMonthCardAction = createAction(
  `${prefix} set percentage invoice paid by month card`,
  props<any>()
);

export const setPercentageRevenueCollectedByTaxPayerCategoryCardAction =
  createAction(
    `${prefix} set percentage revenue collected by tax payer category card`,
    props<any>()
  );

export const setTotalInvoicePaidByBankCardAction = createAction(
  `${prefix} set total invoice paid by bank card`,
  props<any>()
);

export const setPercentageInvoicePaidByTop5MvaCardAction = createAction(
  `${prefix} set percentage invoice paid by top 5 mva card`,
  props<any>()
);

export const setPercentageInvoicePaidByBottom5MvaCardAction = createAction(
  `${prefix} set percentage invoice paid by bottom 5 mva card`,
  props<any>()
);

export const setPercentageInvoicePaidByTop3MdaCardAction = createAction(
  `${prefix} set percentage invoice paid by top 3 mda card`,
  props<any>()
);

export const setPercentageInvoicePaidByBottom3MdaCardAction = createAction(
  `${prefix} set percentage invoice paid by bottom 3 mda card`,
  props<any>()
);
