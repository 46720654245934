import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminDashboardReducerInitialState } from '../reducers/admin-dashboard.reducer';

const getAdminDashboardReducerState =
  createFeatureSelector<AdminDashboardReducerInitialState>('adminDashboard');

export const getAdminDashboardStore = createSelector(
  getAdminDashboardReducerState,
  (state) => state
);
