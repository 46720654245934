import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticsReducerInitialState } from '../reducers/admin-analytics.reducer';

const getAdminAnalyticsReducerState =
  createFeatureSelector<AnalyticsReducerInitialState>('adminAnalytics');

export const getAdminAnalyticsStore = createSelector(
  getAdminAnalyticsReducerState,
  (state) => state
);
